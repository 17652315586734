<script setup lang="ts">
import Decimal from 'decimal.js';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import Step from '@/components/newbie/step.vue';
import VideoFit from '@/components/newbie/video-fit.vue';
import VideoIntroduce from '@/components/newbie/video-introduce.vue';
import Welcome from '@/components/newbie/welcome.vue';
import {
  BRANDWIDTH_ABOUT_EXPIRE_TIP_SHOWED,
  BRANDWIDTH_EXPIRED_TIP_SHOWED,
} from '@/constant/cache';
import { PLAN_NAME } from '@/constant/planName';
import * as ConstantRouter from '@/constant/router';
import BuyBandwidthModal from '@/pages/settings/billing/buy-bandwidth-modal.vue';
import useCurrentPlanStore from '@/store/currentPlan';
import useCurrentResource from '@/store/currentResource';
import useIntro from '@/store/intro';
import useNewGuideStore from '@/store/newGuide';
import usePageStore from '@/store/page';
import usePermissionStore from '@/store/permission';
import useUserStore from '@/store/user';
import { sessionStore } from '@/utils/store';
import { formatCapacity } from '@/utils/tools';

import Error from './coms/error.vue';
import Side from './coms/side.vue';
import Skeleton from './coms/skeleton.vue';
import VHead from './coms/vhead.vue';

const route = useRoute();
const router = useRouter();
const collapsed = ref(false);
const userStore = useUserStore();
const currentPlanStore = useCurrentPlanStore();
const currentResource = useCurrentResource();
const permissionStore = usePermissionStore();
const newGuideStore = useNewGuideStore();

const intro = useIntro();
const welcomeRef = ref();
const videoIntroduceRef = ref();
const videoFitRef = ref();
const stepRef = ref();
const stepShow = ref(false);
const pageStore = usePageStore();
const brandwidthShow = ref(false);
const brandwidthTitle = ref('');
const buyBandwidthModalRef = ref();
// 计算剩余流量
const remainBandwidth = ref(0);

const bandwidthData = computed(() => {
  if (
    currentPlanStore.currentPlanData &&
    [2, 3, undefined].includes(currentPlanStore.currentPlanData.planType)
  ) {
    return null;
  }
  if (
    currentPlanStore.currentPlanData &&
    currentPlanStore.currentPlanData.name.indexOf(PLAN_NAME.PRIMARY) > -1
  ) {
    return null;
  }
  if (!currentResource.detail?.data) return null;
  return {
    usage: currentResource.detail.usedData,
    number: currentResource.detail.data,
  };
});

/** 这里需要等到页面加载完成之后然后再去请求新手引导接口 */
watch(
  () => pageStore.home,
  async val => {
    if (!val) return;
    if (
      currentPlanStore.currentPlanData &&
      +new Date(currentPlanStore.currentPlanData.expireDate) < +new Date()
    ) {
      return;
    }
    if (newGuideStore.guideDetail) return;
    await newGuideStore.fetchGuideInfo();
    if (newGuideStore.error) return;
    if (newGuideStore.guideDetail && newGuideStore.guideDetail.doneWelcome === 0) {
      if (route.name !== ConstantRouter.BROADCASTS) {
        router.push({
          name: ConstantRouter.BROADCASTS,
        });
      }
      welcomeRef.value && welcomeRef.value.show();
    }
  },
  {
    immediate: true,
  },
);

/** 第一步骤进度添加 */
watch(
  () => {
    return intro.one.steps.filter(item => !!item).length === intro.one.count;
  },
  val => {
    if (val) {
      const { intro: oneIntro, steps: oneSteps } = intro.one;
      oneIntro.addSteps(oneSteps);
      oneIntro.oncomplete(async () => {
        stepShow.value = true;
        await nextTick();
        if (stepRef.value) {
          stepRef.value.show();
          stepRef.value.setHasGones(1);
        }
        newGuideStore.updateGuideInfo({ doneWelcome: 1 });
      });
    }
  },
);

/** 第二步骤进度添加 */
watch(
  () => {
    return intro.two.steps.filter(item => !!item).length === intro.two.count;
  },
  val => {
    if (val) {
      const { intro: twoIntro, steps: twoSteps } = intro.two;
      twoIntro.addSteps(twoSteps);
      twoIntro.oncomplete(async () => {
        if (stepRef.value) {
          stepRef.value.show();
          stepRef.value.setHasGones(2);
        }
        newGuideStore.updateGuideInfo({ doneMedia: 1 });
      });
    }
  },
);

/** 第三步骤进度添加 */
watch(
  () => {
    return intro.three.steps.filter(item => !!item).length === intro.three.count;
  },
  val => {
    if (val) {
      const { intro: threeIntro, steps: threeSteps } = intro.three;
      threeIntro.addSteps(threeSteps);
      threeIntro.oncomplete(async () => {
        if (videoFitRef.value) {
          videoFitRef.value.show();
        }
      });
    }
  },
);

watch(
  () => bandwidthData.value,
  bandwidth => {
    if (!bandwidth) return;
    const rate = (bandwidth.number - bandwidth.usage) / bandwidth.number;
    remainBandwidth.value = new Decimal(bandwidth.number)
      .minus(new Decimal(bandwidth.usage))
      .toNumber();
    if (rate <= 0) {
      if (sessionStore.get(BRANDWIDTH_EXPIRED_TIP_SHOWED)) return;
      // 流量已耗尽
      // brandwidthShow.value = true;
      brandwidthTitle.value = 'You have run out of bandwidth for the month…';
      return;
    }
    if (rate <= 0.1) {
      if (sessionStore.get(BRANDWIDTH_ABOUT_EXPIRE_TIP_SHOWED)) return;
      // 流量储存少于百分值5
      // brandwidthShow.value = true;
      brandwidthTitle.value = 'You are running out of bandwidth for the month…';
      return;
    }
    brandwidthShow.value = false;
    brandwidthTitle.value = '';
  },
  {
    immediate: true,
  },
);

onUnmounted(() => {
  intro.allInit();
});

/**
 * 用户、套餐、权限是该应用的入口，必须等到该三个接口都调用完毕之后才能进入应用
 */
const pageLoading = computed(() => {
  return (
    userStore.loading ||
    currentPlanStore.loading ||
    permissionStore.loading ||
    userStore.error?.code === 99999
  );
});

const pageError = computed(() => {
  return userStore.error || currentPlanStore.error || permissionStore.error;
});

function toUpgrade() {
  router.push({
    name: ConstantRouter.PLANS,
  });
}

function welcomeSkip() {
  welcomeRef.value?.hide();
  newGuideStore.updateGuideInfo({ doneWelcome: 1 });
}

function videoIntroduceSure() {
  const { intro: threeIntro } = intro.three;
  threeIntro.start();
}

function videoFitSure() {
  videoFitRef.value.hide();
  newGuideStore.updateGuideInfo({ doneStreaming: 1 });
  stepRef.value.show();
  stepRef.value?.setHasGones(3);
  router.push({
    name: ConstantRouter.SOURCES,
    query: {
      source: 'guide',
    },
  });
}

async function oneStep() {
  stepRef.value?.hide();
  const { intro: oneIntro } = intro.one;
  if (route.name !== ConstantRouter.BROADCASTS) {
    await router.push({
      name: ConstantRouter.BROADCASTS,
    });
    await nextTick();
    setTimeout(() => {
      oneIntro.start();
    }, 500);
    return;
  }
  oneIntro.start();
}

function twoStep() {
  stepRef.value?.hide();
  const { intro: twoIntro } = intro.two;
  twoIntro.start();
}

function threeStep() {
  stepRef.value?.hide();
  videoIntroduceRef.value?.show();
}

function doneAll() {
  stepRef.value?.hide();
  stepShow.value = false;
  newGuideStore.updateGuideInfo({ doneAll: 1 });
}

function closeBandWidthShow() {
  if (!bandwidthData.value) return;
  const rate =
    (bandwidthData.value.number - bandwidthData.value.usage) / bandwidthData.value.number;
  if (rate <= 0) {
    sessionStore.set(BRANDWIDTH_EXPIRED_TIP_SHOWED, true);
  } else if (rate <= 0.1) {
    sessionStore.set(BRANDWIDTH_ABOUT_EXPIRE_TIP_SHOWED, true);
  }
  brandwidthShow.value = false;
}

function toPurchase() {
  buyBandwidthModalRef.value.show();
}
</script>

<template>
  <template v-if="pageLoading">
    <Skeleton></Skeleton>
  </template>
  <template v-else-if="pageError">
    <Error :data="pageError"></Error>
  </template>
  <template v-else>
    <a-layout class="layout-home" v-ga:page="{ operationType: 'login' }">
      <a-layout-sider
        collapsible
        :trigger="null"
        v-model:collapsed="collapsed"
        class="layout-home__sider"
        :width="200"
        :collapsedWidth="60"
      >
        <div class="layout-home__sider-main">
          <!-- <span class="layout-home__sider-toggle" @click="collapsed = !collapsed">
            <i class="g-icon-right-o" v-if="collapsed"></i>
            <i class="g-icon-left-o" v-else></i>
          </span> -->
          <div class="layout-home__sider-inner">
            <Side
              :collapsed="collapsed"
              :className="bandwidthData ? '' : 'no-brandwidth-side'"
            ></Side>
            <a-popover :visible="brandwidthShow" trigger="click" placement="right">
              <template #content>
                <div class="bandwith-tip">
                  <div class="bandwith-tip__title">
                    {{ brandwidthTitle }}
                  </div>
                  <div class="bandwith-tip__content">
                    <p>Please <a @click="toUpgrade">upgrade</a> your YoloCast plan,</p>
                    <p>
                      Or if you need more than these plans offer?
                      <a href="mailto:hazelhe@yololiv.com">Contact Us</a>
                    </p>
                  </div>
                  <div class="bandwith-tip__actions">
                    <a-button type="primary" @click="closeBandWidthShow">I Know</a-button>
                  </div>
                </div>
              </template>
              <div class="storage-remain" v-show="!collapsed" v-if="bandwidthData">
                <div class="storage-item">
                  <div class="storage-title">
                    <span>Bandwidth Remaining</span>
                    <a-popover
                      placement="topLeft"
                      trigger="hover"
                      content="Bandwidth is the data used in order to deliver your videos to viewers."
                    >
                      <i></i>
                    </a-popover>
                  </div>
                  <div class="storage-progress">
                    <div>
                      <a-progress
                        :strokeWidth="6"
                        strokeColor="#ffffff"
                        stroke-linecap="square"
                        :percent="
                          Number(((remainBandwidth / bandwidthData.number) * 100).toFixed(2))
                        "
                        :format="() => ``"
                      />
                      <div class="storage-do">
                        <div class="storage-num">
                          {{ formatCapacity(remainBandwidth) }} of
                          {{ formatCapacity(bandwidthData.number) }}
                        </div>
                        <a
                          class="storage-upgrade"
                          @click="toPurchase"
                          v-if="
                            [1, 4, undefined].includes(currentPlanStore.currentPlanData.planType) &&
                            currentPlanStore.currentPlanData?.name.indexOf(PLAN_NAME.PRIMARY) === -1
                          "
                          >Purchase</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a-popover>
          </div>
        </div>
      </a-layout-sider>
      <a-layout class="layout-home__main">
        <a-layout-header class="layout-home__header">
          <VHead></VHead>
        </a-layout-header>
        <a-layout-content class="layout-home__content">
          <router-view :key="route.path"></router-view>
          <Step
            v-if="stepShow"
            ref="stepRef"
            @one-step="oneStep"
            @two-step="twoStep"
            @three-step="threeStep"
            @done-all="doneAll"
          ></Step>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </template>
  <Welcome ref="welcomeRef" @skip="welcomeSkip"></Welcome>
  <VideoIntroduce ref="videoIntroduceRef" @sure="videoIntroduceSure"></VideoIntroduce>
  <VideoFit ref="videoFitRef" @sure="videoFitSure"></VideoFit>
  <BuyBandwidthModal ref="buyBandwidthModalRef"></BuyBandwidthModal>
</template>

<style lang="scss" scoped>
.layout-home__sider {
  position: relative;
  height: 100vh;
  background: linear-gradient(63deg, #2d4ab8 0%, #142482 100%, #142482 100%);
}

.layout-home__sider-main {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.layout-home__sider-inner {
  position: relative;
  min-height: 100vh;
  padding-bottom: 220px;
}

.layout-home__header {
  position: sticky;
  top: 0;
  z-index: 89;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 30px 0 12px;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(29, 33, 41, 0.12);
}

.layout-home__sider-toggle {
  position: absolute;
  top: 18px;
  right: -12px;
  z-index: 99;
  cursor: pointer;
}

.layout-home__main {
  height: 100vh;
  overflow-x: visible;
  overflow-y: auto;
  background: #fff;
}

.layout-home__content {
  position: relative;
  min-height: unset;
}

.storage-remain {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 15px;

  .storage-item {
    margin-bottom: 10px;
  }

  .storage-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #fff;

    i {
      display: block;
      width: 14px;
      height: 14px;
      margin-left: 3px;
      background: url('@/assets/images/icon-tips-circle.png') no-repeat;
      background-size: 100%;
    }
  }

  .storage-progress {
    :deep {
      .ant-progress-inner {
        background: rgba(255, 255, 255, 0.1) !important;
      }

      .ant-progress-outer {
        padding-right: 0 !important;
      }
    }

    .storage-do {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .storage-num {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
      }

      .storage-upgrade {
        font-size: 12px;
        color: #fff;
        text-decoration: underline;
      }
    }
  }
}

.bandwith-tip {
  width: 297px;
  padding: 3px 4px;
}

.bandwith-tip__title {
  margin-bottom: 15px;
  font-family: Roboto-Medium, Roboto, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #1d2129;
}

.bandwith-tip__content {
  p {
    margin: 0;
  }

  p + p {
    margin-top: 10px;
  }
}

.bandwith-tip__actions {
  margin-top: 20px;
  text-align: right;
}
</style>
