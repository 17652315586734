<script setup lang="ts">
import { useRequest } from 'vue-request';

import { getResourceList, IRsourceDetail } from '@/api/billing';
import { subscriptionPaypal, SubscriptionPaypalInfo } from '@/api/plan';
import CaculatorBandwidth from '@/pages/settings/billing/caculator-bandwidth.vue';
import { countryList } from '@/pages/settings/plans/authConfig';
import PayResult from '@/pages/settings/plans/coms/pay-result.vue';
import useCurrentResource from '@/store/currentResource';
import { newWindow } from '@/utils/tools';

export interface IInstance {
  show: () => void;
  hide: () => void;
}

const emits = defineEmits(['paySuccess']);
const currentResource = useCurrentResource();
const payFormRef = ref();
const payResultRef = ref();
const caculatorBandwidthRef = ref();

const visible = ref<boolean>(false);
const current = ref(0);
const selectResource = ref({} as IRsourceDetail);
// 计算减免的优惠（原价- 实际支付价格）/ 原价
const promoRate = computed(() => {
  if (selectResource.value.id) {
    return (
      (selectResource.value.price - selectResource.value.discountPrice) / selectResource.value.price
    );
  }
  return 1;
});
// 原价
const singlePrice = computed(() => {
  if (selectResource.value.id) {
    return (selectResource.value.price / 100).toFixed(2);
  }
  return '0.00';
});
// 实际价
const discountDinglePrice = computed(() => {
  if (selectResource.value.id) {
    return (selectResource.value.discountPrice / 100).toFixed(2);
  }
  return '0.00';
});
const formState = ref({
  bizId: '',
  country: 'United States',
  zipCode: '',
  address: '',
});
const {
  loading: getResourceListLoading,
  data: getResourceListData,
  run: getResourceListRun,
  error: getResourceListError,
} = useRequest(getResourceList, {
  formatResult(res) {
    return res.data;
  },
  manual: true,
});
const {
  loading: subscriptionPaypalLoading,
  data: subscriptionPaypalData,
  run: subscriptionPaypalRun,
  error: subscriptionPaypalError,
} = useRequest(subscriptionPaypal, {
  formatResult(res) {
    return res.data;
  },
  manual: true,
});

const filterOption = (input: any, option: any) => {
  return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

function clearVaild() {
  payFormRef.value.clearValidate('zipCode');
}

async function show() {
  formState.value = {
    bizId: '',
    country: 'United States',
    zipCode: '',
    address: '',
  };
  visible.value = true;
  current.value = 0;
  await getResourceListRun({ resourceType: 1 });
  if (getResourceListError.value) return;
  formState.value.bizId = getResourceListData.value![0].id as any;
  selectResource.value = getResourceListData.value![0];
}

function hide() {
  visible.value = false;
}

function cancel() {
  visible.value = false;
}

function toNext() {
  if (!formState.value.bizId) {
    return false;
  }
  current.value = 1;
}

async function purchase() {
  payFormRef.value.validateFields().then(async () => {
    formState.value.zipCode = formState.value.zipCode.trim();
    formState.value.address = formState.value.address.trim();
    await subscriptionPaypalRun({
      bizId: Number(formState.value.bizId),
      country: formState.value.country,
      address: formState.value.address || undefined,
      zipCode: formState.value.zipCode || undefined,
      bizType: 2,
    });
    if (subscriptionPaypalError.value) return;
    const isOpened = await newWindow(subscriptionPaypalData.value?.approveUrl as string);
    if (isOpened) {
      visible.value = false;
      purchaseResult(subscriptionPaypalData.value as SubscriptionPaypalInfo);
    }
  });
}

function contact() {
  window.open('mailto:hazelhe@yololiv.com');
}

function getSelectResource() {
  selectResource.value = getResourceListData.value!.find(
    item => item.id === formState.value.bizId,
  ) as IRsourceDetail;
}

function purchaseResult(data: SubscriptionPaypalInfo) {
  payResultRef.value.show({ ...data, bizType: 2 });
}
async function paySuccess() {
  await currentResource.fetchCurrentResource();
  emits('paySuccess');
}
function showCalc() {
  caculatorBandwidthRef.value.show();
}

defineExpose({
  show,
  hide,
});
</script>

<template>
  <a-modal
    v-model:visible="visible"
    width="520px"
    :footer="null"
    wrapClassName="modal-setting-bandwidth-confirm g-noborder-header-modal g-notoppadding-modal"
    @cancel="cancel"
  >
    <template #title>
      <div class="setting-bandwidth-modal-title">
        <i></i>
        Purchase Bandwidth
      </div>
    </template>
    <a-spin :spinning="getResourceListLoading">
      <div class="step-box">
        <div class="step-cart" @click="current = 0">
          <div class="step-active" v-if="current === 0">1. Cart</div>
          <div class="step-ok" v-else>
            <i></i>
            Cart
          </div>
        </div>
        <div class="step-next-icon" :class="{ gray: current === 0, white: current !== 0 }"></div>
        <div class="step-payment" @click="toNext">
          <div class="step-wait" v-if="current === 0">2. Payment</div>
          <div class="step-active" v-else>2. Payment</div>
        </div>
      </div>
      <div
        class="pay-confirm-box"
        :class="{ 'pay-confirm-first': current === 0, 'pay-confirm-second': current === 1 }"
      >
        <div class="pay-confirm-cart" v-if="current === 0">
          <div class="pay-confirm-tip">
            Sharing the livestream or video with the audience through embedding or sharing links
            will consume bandwidth and data usage.
          </div>
          <div class="pay-confirm-item-first pay-confirm-item-first-select">
            <div class="item-select">
              <span class="pay-confirm-label">Amount in Gigabytes(GB)</span>
              <a-select
                style="width: 100%"
                v-model:value="formState.bizId"
                @change="getSelectResource"
              >
                <a-select-option
                  v-for="item in getResourceListData || []"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="pay-confirm-item-first">
            <span class="pay-confirm-label pay-confirm-label-total">Total Pay Now:</span>
            <div class="pay-confirm-price">
              <span class="main-price"> ${{ discountDinglePrice }} </span>
              <s class="origin-price">${{ singlePrice }}</s>
            </div>
          </div>
          <div class="fingle-line"></div>
          <div class="help-box">
            Need help with purchase additional bandwidth? Visit
            <a @click="showCalc">Bandwidth Calculator</a>,<br />
            Or <a @click="contact">Contact Us</a>.
          </div>
          <div class="pay-confirm-footer-first" v-if="current === 0">
            <a-button key="back" @click="cancel"> Cancel </a-button>
            <a-button key="next" type="primary" @click="toNext"> Next </a-button>
          </div>
        </div>
        <div class="pay-confirm-payment" v-if="current === 1">
          <div class="pay-confirm-main">
            <div class="pay-detail-text">Details & payment method:</div>
            <div class="pay-methods-box">
              <div class="pay-methods-text">Payment method</div>
              <div class="pay-methods-icon"></div>
              <div class="pay-other-tips-icon"></div>
              <div class="pay-other-tips-text">
                For other payment methods please
                <a href="mailto:hazelhe@yololiv.com">contact us</a>
              </div>
            </div>
            <div class="pay-form-box">
              <a-form layout="vertical" :model="formState" autocomplete="off" ref="payFormRef">
                <div class="counter-box">
                  <div class="label-country">
                    <a-form-item label="Country/Region" name="country">
                      <a-select
                        v-model:value="formState.country"
                        show-search
                        :filter-option="filterOption"
                        @change="clearVaild"
                      >
                        <a-select-option
                          v-for="item in countryList"
                          :key="item.name"
                          :value="item.name"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="label-zipcode">
                    <a-form-item label="Zip Code" name="zipCode">
                      <a-input v-model:value="formState.zipCode" />
                    </a-form-item>
                  </div>
                </div>
                <div class="adress-box">
                  <a-form-item label="Address" name="address">
                    <a-input v-model:value="formState.address" />
                  </a-form-item>
                </div>
              </a-form>
            </div>
            <div class="pay-msg">
              When you click Purchase, You’ll be redirected to PayPal, where you can review your
              order before you purchase.
            </div>
            <div class="pay-confirm-item promo-item">
              <div class="promo-result">
                <span class="pay-subtotal-label">Subtotal</span>
                <span class="pay-subtotal-price">
                  <span class="main-price">${{ (selectResource.price / 100).toFixed(2) }}</span>
                </span>
              </div>
              <div class="promo-result">
                <span class="pay-off-label">
                  Sale:
                  <span>{{ (promoRate * 100).toFixed(0) }}% off</span>
                </span>
                <span class="pay-off-price">
                  <span class="promo-price"
                    >-${{
                      ((selectResource.price - selectResource.discountPrice) / 100).toFixed(2)
                    }}</span
                  >
                </span>
              </div>
            </div>
            <div class="fingle-line-current1"></div>
            <div class="pay-confirm-item">
              <span class="pay-confirm-label">Total Pay Now</span>
              <span class="pay-confirm-price">
                <span class="main-price"
                  >${{ (selectResource.discountPrice / 100 || 0).toFixed(2) }}</span
                >
              </span>
            </div>
          </div>
          <div class="pay-confirm-footer-second">
            <a-button
              key="purchase"
              type="primary"
              @click="purchase"
              class="pay-btn"
              :loading="subscriptionPaypalLoading"
            >
              Purchase by
              <i></i>
            </a-button>
            <a-button key="cancel" type="text" @click="cancel" class="cancel-btn">
              Cancel
            </a-button>
          </div>
          <span class="agree-tips"
            >By purchasing this product I acknowledge and accept the
            <a href="https://yololiv.com/terms-of-service" target="_blank">Terms of Services</a>
            and
            <br />
            <a href="https://yololiv.com/privacy" target="_blank">Privacy Policy</a>.</span
          >
        </div>
      </div>
    </a-spin>
  </a-modal>
  <PayResult ref="payResultRef" @complete="paySuccess"></PayResult>
  <CaculatorBandwidth ref="caculatorBandwidthRef"></CaculatorBandwidth>
</template>

<style lang="scss">
.modal-setting-bandwidth-confirm {
  .ant-modal-body {
    padding: 20px 20px 10px 20px;
  }

  .setting-bandwidth-modal-title {
    display: flex;
    align-items: center;
    font-weight: 600;

    i {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      background: url(@/assets/images/setting/icon-find-yourself.png) no-repeat;
      background-size: 100%;
    }
  }

  .step-box {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;

    .step-cart {
      box-sizing: border-box;
      width: 50%;
      height: 40px;
      line-height: 38px;
      cursor: pointer;
      border: 1px solid #c9cdd4;
      border-right: 0;
    }

    .step-payment {
      box-sizing: border-box;
      width: 50%;
      height: 40px;
      line-height: 38px;
      cursor: pointer;
      border: 1px solid #c9cdd4;
      border-left: 0;
    }

    .step-next-icon {
      position: absolute;
      left: 50%;
      width: 28px;
      height: 28px;
      border: 1px solid #c9cdd4;
      border-bottom: 0;
      border-left: 0;
      transform: translate(-50%, 0) rotate(45deg);

      &.gray {
        background: #f2f3f5;
      }

      &.white {
        background-color: #fff;
      }
    }

    .step-active {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      color: #1d2129;
      background: #f2f3f5;
    }

    .step-ok {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #86909c;

      i {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        background: url(@/assets/images/setting/icon-step-ok.png) no-repeat;
        background-size: 100%;
      }
    }

    .step-wait {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #86909c;
    }
  }

  .pay-confirm-box {
    width: 100%;
    overflow: hidden;
    transition: all 0.3s;

    &.pay-confirm-first {
      height: 298px;
      transition: all 0.3s;
    }

    &.pay-confirm-second {
      height: 604px;
      transition: all 0.3s;

      .pay-confirm-payment {
        transition: all 0.3s;
        transform: translateY(0);
      }
    }
  }

  .pay-confirm-cart {
    .pay-confirm-tip {
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: #ff0043;
    }

    .pay-confirm-item-first {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 23px;

      .pay-confirm-label {
        font-size: 14px;
        color: #4e5969;
      }

      .pay-confirm-price {
        position: relative;
        display: flex;
        align-items: center;

        .price-per {
          position: absolute;
          top: -30px;
          right: 0;
          font-size: 14px;
          font-weight: 600;
          color: #1d2129;
        }

        .main-price {
          font-size: 20px;
          font-weight: 600;
          color: #ff0043;
        }

        .origin-price {
          margin-left: 4px;
          font-size: 12px;
          color: #86909c;
        }
      }

      .pay-confirm-label-total {
        font-size: 14px;
        font-weight: 600;
        color: #1d2129;
      }

      &.pay-confirm-item-first-select {
        margin-bottom: 20px;

        .item-select {
          width: 100%;

          .pay-confirm-label {
            display: block;
            margin-bottom: 10px;
          }
        }
      }
    }

    .fingle-line {
      width: 100%;
      height: 1px;
      margin: 0 0 10px 0;
      box-shadow: inset 0 -1px 0 0 rgba(229, 230, 235, 0.5);
    }

    .help-box {
      font-size: 12px;
      line-height: 16px;
      color: #1d2129;

      a {
        text-decoration: underline;
      }
    }

    .pay-confirm-footer-first {
      display: flex;
      justify-content: end;
      margin-top: 30px;
      margin-bottom: 10px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 32px;
        padding: 0;

        &:last-child {
          margin-left: 15px !important;
        }
      }
    }
  }

  .pay-confirm-payment {
    width: 100%;
    transition: all 0.3s;
    transform: translateY(-315px);

    .pay-confirm-main {
      .pay-detail-text {
        margin-bottom: 19px;
        font-size: 16px;
        line-height: 19px;
        color: #86909c;
      }

      .pay-methods-box {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;

        .pay-methods-text {
          margin-right: 10px;
          font-size: 14px;
          line-height: 18px;
          color: #4e5969;
        }

        .pay-methods-icon {
          width: 69px;
          height: 32px;
          margin-right: 10px;
          background: url(@/assets/images/setting/icon-paypal-b.png) no-repeat;
          background-size: 100%;
        }

        .pay-other-tips-icon {
          width: 14px;
          height: 14px;
          margin-right: 4px;
          background: url(@/assets/images/icon-tips-circle-gray.png) no-repeat;
          background-size: 100%;
        }

        .pay-other-tips-text {
          font-size: 12px;
          color: #86909c;
        }
      }

      .pay-form-box {
        label {
          font-weight: 600;
          color: #1d2129 !important;
        }

        .ant-form-item {
          margin-bottom: 20px;
        }

        .ant-form-item-explain-error {
          font-size: 12px;
        }

        .counter-box {
          display: flex;

          .label-country {
            flex: 1;

            .anticon-search {
              display: none !important;
            }
          }

          .label-zipcode {
            width: 120px;
            margin-left: 20px;
          }
        }
      }

      .pay-confirm-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pay-confirm-label {
          font-size: 16px;
          font-weight: 600;
          color: #1d2129;
        }

        .pay-confirm-price {
          position: relative;
          display: flex;
          align-items: center;

          .main-price {
            font-size: 18px;
            font-weight: 600;
            color: #ff0043;
          }
        }
      }

      .fingle-line {
        width: 100%;
        height: 1px;
        margin: 25px 0 40px 0;
        box-shadow: inset 0 -1px 0 0 rgba(229, 230, 235, 0.5);
      }

      .fingle-line-current1 {
        width: 100%;
        height: 1px;
        margin: 13px 0;
        box-shadow: inset 0 -1px 0 0 rgba(229, 230, 235, 0.5);
      }

      .pay-msg {
        font-size: 14px;
        line-height: 20px;
        color: #4e5969;
      }

      .promo-item {
        flex-wrap: wrap;

        .main-price {
          color: #1d2129 !important;
        }

        .promo-result {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-top: 14px;

          .pay-subtotal-label,
          .pay-off-label {
            font-size: 14px;
            color: #4e5969 !important;
          }

          .pay-subtotal-price {
            .main-price {
              font-size: 14px;
              font-weight: 600;
              color: #1d2129;
            }
          }

          .pay-off-price {
            .promo-price {
              font-size: 14px;
              font-weight: 600;
              color: #ff0043 !important;
            }
          }
        }
      }
    }

    .pay-confirm-footer-second {
      margin: 30px 0;

      .pay-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 36px;
        padding: 0;

        i {
          display: block;
          width: 53px;
          height: 14px;
          margin-left: 5px;
          background: url(@/assets/images/setting/icon-paypal-btn.png) no-repeat;
          background-size: 100%;
        }
      }

      .cancel-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
        color: #86909c;
      }
    }

    .agree-tips {
      display: block;
      width: 460px;
      margin: 0 auto 5px;
      font-size: 12px;
      line-height: 16px;
      color: #1d2129;

      a {
        text-decoration: underline;
      }
    }
  }
}
</style>
