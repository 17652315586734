import { Button, Modal } from 'ant-design-vue';

import * as ConstantRouter from '@/constant/router';
import {
  PACKAGE_DEFAULT_ACCESS_DESC,
  PACKAGE_DEFAULT_ACCESS_TITLE,
  PACKAGE_DEFAULT_EXPIRED_DESC,
  PACKAGE_DEFAULT_EXPIRED_TITLE,
} from '@/constant/tip';
import router from '@/router';
import useCurrentPlanStore from '@/store/currentPlan';

export default function upgradeTip(
  { title, desc, close, okText, cancelShow = false } = {} as {
    title?: string;
    desc?: string;
    close?: boolean;
    okText?: string;
    cancelShow?: boolean;
  },
) {
  const currentPlanStore = useCurrentPlanStore();
  let defaultTitle = PACKAGE_DEFAULT_ACCESS_TITLE,
    defaultDesc = PACKAGE_DEFAULT_ACCESS_DESC;
  if (
    currentPlanStore.currentPlanData &&
    +new Date(currentPlanStore.currentPlanData.expireDate) < +new Date()
  ) {
    // close = false;
    defaultTitle = PACKAGE_DEFAULT_EXPIRED_TITLE;
    defaultDesc = PACKAGE_DEFAULT_EXPIRED_DESC;
  }
  Modal.destroyAll();
  Modal.confirm({
    content: h('div', [
      h('p', desc || defaultDesc),
      h('p', [
        "If you'd like to try this feature out in trial period,",
        h(
          Button,
          {
            type: 'link',
            onClick: () => {
              window.open('mailto:hazelhe@yololiv.com');
            },
          },
          { default: () => 'Contact Us' },
        ),
      ]),
    ]),
    okText: okText || 'Upgrade',
    cancelText: 'Cancel',
    type: 'warning',
    title: title || defaultTitle,
    width: '520px',
    wrapClassName: 'modal-end-of-trial',
    maskClosable: close === undefined ? true : close,
    keyboard: close === undefined ? true : close,
    closable: close === undefined ? true : close,
    // @ts-ignore
    cancelButtonProps: { style: { display: cancelShow ? 'inline-block' : 'none' } },
    onOk: () => {
      router.push({
        name: ConstantRouter.PLANS,
      });
    },
  });
}
